<template>
    <div>
        <HeaderB />
        <div class="content">

            <div class="Bloque1">

                <div class="B1F1">
                    <div class="B1F1C1">
                        <div class="B1F1C1_C1">
                            <img src="../../assets/logo1_1.png">
                        </div>
                        <div class="B1F1C1_C2">
                            <p>LABORATORIO CLÍNICO BARRAGAN</p>
                        </div>
                    </div>

                    <div class="B1F1C2">
                        <div class="B1F1C2_C1">
                            <ul>
                                <li @click="cambiarOpcion('Home')" :class="{ selector: this.opcion == 'Home' }"><a
                                        href="/barragan">Inicio</a></li>
                                <li @click="cambiarOpcion('Nosotros')" :class="{ selector: this.opcion == 'Nosotros' }">
                                    <a @click="redirect('nosotros')">Nosotros</a>
                                </li>
                                <li @click="cambiarOpcion('Servicios')"
                                    :class="{ selector: this.opcion == 'Servicios' }"><a href="#">Servicios</a>
                                </li>
                                <li @click="cambiarOpcion('Certificaciones')"
                                    :class="{ selector: this.opcion == 'Certificaciones' }">
                                    <a @click="redirect('certificaciones')">Certificaciones</a>
                                </li>
                                <li @click="cambiarOpcion('Contacto')" :class="{ selector: this.opcion == 'Contacto' }">
                                    <a href="#contacto">Contacto</a>
                                </li>
                                <li @click="cambiarOpcion('Privacidad')"
                                    :class="{ selector: this.opcion == 'Privacidad' }">
                                    <a href="/barragan/politica">Aviso Privacidad</a>
                                </li>
                                <li :class="{ selector: this.opcion == 'Quejas' }">
                                    <a href="#" @click="cambiarOpcion('Quejas')">Quejas</a>

                                    <div class="wrapper" :class="{ 'extended': opcion == 'Quejas' }">
                                        <div>
                                            <h2>¿Tienes alguna queja o sugerencia para nuestro servicio? </h2>
                                            <a href="mailto:dirección@laason.mx">Envíanos un mensaje</a>
                                            <ol>
                                                <li>Envíanos un mensaje por medio de este botón o al correo <a
                                                        href="mailto:dirección@laason.mx">dirección@laason.mx</a>
                                                </li>
                                                <li>Te confirmaremos de recibida tu queja o sugerencia, si es una queja
                                                    recibe y
                                                    analiza las causas que la originaron si procede o no en un plazo
                                                    máximo de
                                                    10 días hábiles.</li>
                                                <li>Se tomaran las acciones pertinentes y nos comunicaremos contigo.
                                                </li>
                                            </ol>
                                            <h3>Muchas gracias por ser parte de nuestra mejora continua.</h3>

                                            <button @click="cambiarOpcion('Servicio')">Salir</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div class="B1F2">
                    <div class="B1F2C1">
                        <div class="B1F2C1_F1">
                            <p>Servicios</p>
                        </div>
                        <div class="B1F2C1_F2">
                            <p>Nuestros filtros están diseñados, construidos y certificados según las normas sanitarias.
                            </p>
                        </div>
                    </div>
                    <img class="B1F2C2" src="../../assets/servicios3_1.png">
                </div>

            </div>

            <div id="analisis-clinicos" class="Bloque2">
                <div class="B2B1">
                    <img src="../../assets/SB_17.png">
                </div>
                <div>
                    <div class="B2_F1">
                        <div class="B2_C1">
                            <img src="../../assets/icocuatroP.png">
                        </div>
                        <div class="B2_C2">
                            <p>Análisis clínicos</p>
                        </div>
                    </div>
                    <div class="B2_F2">
                        <p>Somos un Laboratorio de Análisis Clínicos reconocido por su calidez y calidad profesional.
                            Contamos con una amplia de gama de estudios tanto de rutina como de alta especialidad.</p>
                        <p>Nuestro compromiso es brindarte resultados rápidos y confiables a precios competitivos. </p>
                    </div>
                </div>
            </div>

            <div class="Bloque3"></div>

            <div id="pruebas-de-antidoping" class="Bloque4">
                <div>
                    <div class="B4_F1">
                        <div class="B4_C1">
                            <img src="../../assets/Frame.png">
                        </div>
                        <div class="B4_C2">
                            <p>Pruebas de Antidoping</p>
                        </div>
                    </div>
                    <div class="B4_F2">
                        <p>Realizamos todo tipo de pruebas de antidoping para detectar el consumo de sustancias
                            ilegales.</p>
                        <p>Solicita el servicio de manera particular o como protocolo para tus empleados durante su
                            proceso de contratación.</p>
                    </div>
                </div>
                <div class="B4B2">
                    <img src="../../assets/SB_18.png">
                </div>
            </div>

            <div class="Bloque5"></div>

            <div class="Bloque6P">

                <div>
                    <div class="B8_F1">
                        <div class="B8_C1">
                            <img src="../../assets/Frame_4.png">
                        </div>
                        <div class="B8_C2">
                            <p>Pruebas Covid</p>
                        </div>
                    </div>
                    <div class="B8_F2">
                        <p>Realízate alguna de las 3 pruebas para la detección de COVID: PCR | Antígenos | Anticuerpos.
                        </p>
                        <p>Para tu mayor seguridad y comodidad, puedes recibir la atención a domicilio.</p>
                    </div>
                </div>

                <div class="B8B2">
                    <img src="../../assets/SB_20.png">
                </div>

            </div>
            <div id="filtros-sanitarios" class="Bloque6">
                <div class="B6B1">
                    <img src="../../assets/SB_19.png">
                </div>
                <div>
                    <div class="B6_F1">
                        <div class="B6_C1">
                            <img src="../../assets/Frame_3.png">
                        </div>
                        <div class="B6_C2">
                            <p>Filtros sanitarios para la manipulación de alimentos</p>
                        </div>
                    </div>
                    <div class="B6_F2">
                        <p>Te proveemos de las instalaciones necesarias para garantizar la higiéne adecuada para
                            producir alimentos sin riesgo. Nuestros filtros están diseñados, construidos y certificados
                            según las normas sanitarias.</p>
                    </div>
                </div>
            </div>

            <div class="Bloque7"></div>

            <div class="Bloque8P">
                <div class="B6B1">
                    <img src="../../assets/SB_19.png">
                </div>
                <div>
                    <div class="B6_F1">
                        <div class="B6_C1">
                            <img src="../../assets/Frame_3.png">
                        </div>
                        <div class="B6_C2">
                            <p>Filtros sanitarios para la manipulación de alimentos</p>
                        </div>
                    </div>
                    <div class="B6_F2">
                        <p>Te proveemos de las instalaciones necesarias para garantizar la higiéne adecuada para
                            producir alimentos sin riesgo. Nuestros filtros están diseñados, construidos y certificados
                            según las normas sanitarias.</p>
                    </div>
                </div>
            </div>

            <div id="pruebas-covid" class="Bloque8">

                <div>
                    <div class="B8_F1">
                        <div class="B8_C1">
                            <img src="../../assets/Frame_4.png">
                        </div>
                        <div class="B8_C2">
                            <p>Pruebas Covid</p>
                        </div>
                    </div>
                    <div class="B8_F2">
                        <p>Realízate alguna de las 3 pruebas para la detección de COVID: PCR | Antígenos | Anticuerpos.
                        </p>
                        <p>Para tu mayor seguridad y comodidad, puedes recibir la atención a domicilio.</p>
                    </div>
                </div>

                <div class="B8B2">
                    <img src="../../assets/SB_20.png">
                </div>

            </div>

            <div class="Bloque9"></div>
            <div class="Bloque10">
                <div class="B10B1">
                    <img src="../../assets/Rectangle_21P.png">
                </div>
                <div>
                    <div class="B10_F1">
                        <p>Otros análisis</p>
                    </div>
                    <div class="B10_F2">
                        <ul>
                            <li>Vida de anaquel</li>
                            <li>Reto Microbiano</li>
                        </ul>
                        <ul>
                            <li>Sustancias tóxicas</li>
                            <li>Entre otros</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="Bloque11"></div>

            <div class="Bloque12" id="contacto">
                <div class="B12B1">
                    <img src="../../assets/Rectangle_25P.png">
                </div>

                <div class="B12_Esp">
                    <div class="B12_F1">
                        <p>¿Dudas? Mándanos un mensaje.</p>
                    </div>
                    <form @submit.prevent="
                        submit(
                            name,
                            phone,
                            mail,
                            message
                        )
                        ">
                        <div class="B12_F2">
                            <label for="Nombre">Nombre</label>
                            <input name="Nombre">
                        </div>
                        <div class="B12_F2">
                            <label for="Telefono">Télefono</label>
                            <input name="Telefono">
                        </div>
                        <div class="B12_F2">
                            <label for="Correo">Correo</label>
                            <input name="Correo">
                        </div>
                        <div class="B12_F5">
                            <label for="Mensaje">Mensaje</label>
                            <textarea name="Mensaje"></textarea>
                        </div>
                        <div class="B12_F6">
                            <button>Enviar</button>
                        </div>

                    </form>
                    <div class="B12_F7">
                        <p><span>Tels.</span> (662) 213.23.02 y (662) 313.06.50.</p>
                        <p><span>Mail.</span> barragan@laason.mx</p>
                        <p><span>Dirección:</span> Garmendia N°80, Col.San Benito C.P. 83190. Hermosillo, Sonora.</p>
                    </div>
                    <div class="EB12_F7">
                        <div class="Eb12_F1">
                            <p><span>Tels.</span> (662) 213.23.02 y (662) 313.06.50.</p>
                            <p><span>|</span></p>
                            <p><span>Mail.</span> barragan@laason.mx</p>
                        </div>
                        <p><span>Dirección:</span> Garmendia N°80, Col.San Benito C.P. 83190. Hermosillo, Sonora.</p>
                    </div>
                </div>

            </div>
            <div class="Bloque13"></div>
            <div class="Bloque14">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3486.378370506103!2d-110.95978618472249!3d29.094508882237868!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce850526e3f02f%3A0xbbb1453832ac2b5b!2sLaboratorio%20de%20Alimentos%20y%20Aguas%20de%20Sonora%20S.A.%20de%20C.V.!5e0!3m2!1ses!2smx!4v1638914584188!5m2!1ses!2smx"
                    width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
        </div>
        <FooterSec :name="this.opc" />
    </div>
</template>


<script>
import HeaderB from '../HeaderB.vue';
import FooterSec from '../FooterSec.vue';

export default {
    name: 'ServiciosBarragan',
    data() {
        return {
            opcion: "Servicios",
            opc: "barraganService",
            name: "",
            phone: "",
            mail: "",
            message: ""

        }
    },
    components: {
        HeaderB,
        FooterSec
    },

    created() {
        const direccion = localStorage.getItem("direccion");

        if (direccion) {
            localStorage.removeItem("direccion");
            setTimeout(() => {
                window.location.href = `#${direccion}`
            }, 600);
        }

    },

    computed: {
        seleccionar: function () {
            return { color: '#a94442' };
        }
    },
    methods: {
        cambiarOpcion(opcion) {
            this.opcion = opcion;
        },

        redirect(direccion) {
            this.$router.push("/barragan")

            setTimeout(() => {
                window.location.href = `#${direccion}`
            }, 600);
        }
    }

}
</script>

<style scoped>
/*Estos estilos comienzan con Movil y en mediaquery estan los estilos de escritorio*/


.selector {
    border: 1px solid white;
    border-radius: 3vw;
    text-align: center;
    padding: 0;
    margin: 0vw !important;
}

.Bloque1 {
    display: block;
    width: 100%;
    height: 160.14492753623188VW;
    background: linear-gradient(243.78deg, #6FBA91 17.49%, #0A4CA1 99.32%);
    text-align: -webkit-center;
    padding-top: 0vw;
}

.B1F1 {
    width: 86.88405797101449VW;
    border-bottom: 1px solid white;
    padding-bottom: 4.830917874396135VW;
    margin-bottom: 14vw;
}

.B1F1C1 {
    display: flex;
    align-items: center;
}

.B1F1C1_C1 {
    margin-top: 4vw;
}

.B1F1C1_C1 img {
    width: 13.285024154589372VW;
    height: auto;
}

.B1F1C1_C2 {
    width: 32vw;
    height: 9vw;
    margin-left: 3.3816425120772946VW;
    padding-top: 5vw;
}

.B1F1C1_C2 p {
    color: white;
    font-size: 3.140096618357488VW;
    line-height: 4.270531400966184VW;
    font-weight: 700;
    text-align: left;
}

.B1F1C2 {
    display: none;
}

img.B1F2C2 {
    width: 99.903382VW;
    height: auto;
}

.B1F2C1_F1 p {
    color: #BBEFE3;
    font-size: 12.077294685990339VW;
    font-weight: 800;
    margin-bottom: 6.038647342995169VW;
}

.B1F2C1_F2 p {
    width: 59vw;
    color: white;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    margin-bottom: 3.4vw;
}




.Bloque2 {
    width: 100%;
    height: 112.31884057971016VW;
    text-align: -webkit-center;
}

.B2B1 {
    display: none;
}

.B2_F1 {
    display: flex;
    justify-content: center;
    margin-top: 21.73913043478261VW;
}

.B2_C2 {
    width: 46vw;
    text-align: left;
    padding-top: 6vw;
}

.B2_C1 img {
    width: 13.915458937198066VW;
    height: 14.734299516908212VW;
    margin-right: 5.797101449275362VW
}

.B2_C2 p {
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
    margin-bottom: 0vw;
}

.B2_F2 {
    width: 65vw;
    height: 27vw;
    margin-top: 14.492753623188406VW;
}

.B2_F2 p {
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: center;
    color: #A6A6A6;
}

.Bloque3 {
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/SB_17P.png");
    display: block;
}

.Bloque4 {
    width: 100%;
    height: 79.22705314009661VW;
    text-align: -webkit-center;
}

.B4B2 {
    display: none;
}

.B4_F1 {
    display: flex;
    justify-content: center;
    margin-top: 18.357487922705314VW;
}

.B4_C2 {
    width: 44vw;
    text-align: left;
    ;
}

.B4_C1 img {
    width: 18.59903381642512VW;
    height: 15.340579710144928VW;
    margin-right: 5.797101449275362VW
}

.B4_C2 p {
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
}

.B4_F2 {
    width: 63vw;
    height: 10vw;
    margin-top: 7.729468599033816VW;
}

.B4_F2 p {
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: center;
    color: #A6A6A6;
}

.Bloque5 {
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/SB_18P.png");
}


.Bloque6P {
    width: 100%;
    height: 136.23188405797103VW;
    text-align: -webkit-center;
}

.Bloque6 {
    display: none;
    width: 100%;
    height: 94.92753623188406VW;
    text-align: -webkit-center;
}

.B6B1 {
    display: none;
}

.B6_F1 {
    display: flex;
    justify-content: center;
    margin-top: 18.357487922705314VW;
}

.B6_C2 {
    width: 53vw;
    text-align: left;
    ;
}

.B6_C1 img {
    width: 16.666666666666664VW;
    height: 17.632850241545896VW;
    margin-right: 5.797101449275362VW
}

.B6_C2 p {
    display: block;
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
    font-family: Arial, Helvetica, sans-serif;
}

.B6_C2 pre {
    display: none;
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
    background-color: white;
    border: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.B6_F2 {
    width: 64vw;
    height: 27vw;
    margin-top: 7.729468599033816VW;
    margin-left: 7.7vw;
}

.B6_F2 p {
    display: block;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: center;
    color: #A6A6A6;
}

.B6_F2 pre {
    display: none;
    background-color: white;
    border: 0;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: center;
    color: #A6A6A6;
    font-family: Arial, Helvetica, sans-serif;
}

.Bloque7 {
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/SB_20P.png");
    display: block;
}

.Bloque8P {
    width: 100%;
    height: 94.92753623188406VW;
    text-align: -webkit-center;
}

.Bloque8 {
    display: none;
    width: 100%;
    height: 136.23188405797103VW;
    text-align: -webkit-center;
}

.B8B2 {
    display: none;
}

.B8_F1 {
    display: flex;
    justify-content: center;
    margin-top: 21.73913043478261VW;
}

.B8_C2 {
    width: 33vw;
    text-align: left;
    ;
}

.B8_C1 img {
    width: 20.396135265700483VW;
    height: 22.946859903381643VW;
    margin-right: 5.797101449275362VW
}

.B8_C2 p {
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
}

.B8_F2 {
    width: 60vw;
    height: 52vw;
    margin-top: 14.492753623188406VW;
    text-align: left;
    margin-left: 4.7vw;
}

.B8_F2 p {
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: center;
    color: #A6A6A6;
    margin-bottom: 5vw;
}

.B8_F2 ul {
    color: #A6A6A6;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    padding-left: 6vw;
}

.Bloque9 {
    display: none;
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/Rectangle_20P.png");
}

.Bloque10 {
    width: 100%;
    height: 59.17874396135265VW;
    text-align: -webkit-center;
    display: none;
}

.B10B1 {
    display: none;
}

.B10_F1 {
    width: 67.6328502415459VW;
    height: 7.004830917874397VW;
    margin-top: 15.217391304347828VW;
}

.B10_F1 p {
    font-size: 5.797101449275362VW;
    color: #548EBA;
    font-weight: 800;
    text-align: left;
}

.B10_F2 {
    display: flex;
    justify-content: center;
    margin-top: 11.11111111111111VW;
}

.B10_F2 ul {
    color: #A6A6A6;
    font-size: 3.140096618357488VW;
    font-weight: 400;
    line-height: 5.589371980676329VW;
    letter-spacing: 1.5%;
    text-align: left;
    margin-right: 7vw;
}

.Bloque11 {
    display: none;
    width: 100%;
    height: 62.31884057971014VW;
    background-image: url("../../assets/Rectangle_21P.png");
}

.Bloque12 {
    width: 100%;
    height: 280.19323671497585VW;
    background-color: #DEEFFF;
    text-align: -webkit-center;
    padding-top: 14.734299516908212VW;
}

.B12B1 {
    display: none;
}

.B12_F1 {
    width: 62vw;
    height: 30vw;
    text-align: left;
    margin-bottom: 6.763285024154589VW;
    margin-left: -7vw;
}

.B12_F1 p {
    font-size: 8.454106280193237VW;
    line-height: 10.144927536231885VW;
    font-weight: 800;
    color: #0A4CA1;
}

.B12_F2 {
    width: 80vw;
    height: 24vw;
    text-align: left;
    margin-bottom: 6.521739130434782VW;
}

.B12_F2 label {
    color: #0A4CA1;
    font-size: 3.864734299516908VW;
    line-height: 4.63768115942029VW;
    font-weight: 800;
    margin-bottom: 4vw;
    padding-left: 5.5vw;
}

.B12_F2 input {
    width: 79.95169082125604VW;
    height: 14.734299516908212VW;
    border: 1px solid #0A4CA1;
    border-radius: 8vw;
    background: transparent;
    padding: 0vw 6vw;
}


.B12_F5 {
    width: 80vw;
    height: 55vw;
    text-align: left;
    margin-bottom: 6.521739130434782VW;
}

.B12_F5 label {
    color: #0A4CA1;
    font-size: 3.864734299516908VW;
    line-height: 4.63768115942029VW;
    font-weight: 800;
    margin-bottom: 4vw;
    padding-left: 5.5vw;
}

.B12_F5 textarea {
    width: 79.95169082125604VW;
    height: 46.13526570048309VW;
    border: 1px solid #0A4CA1;
    border-radius: 8vw;
    background: transparent;
    padding: 3vw 6vw;
}

.B12_F6 {
    margin-top: 2vw;
}

.B12_F6 button {
    width: 79.95169082125604VW;
    height: 14.734299516908212VW;
    color: white;
    border-radius: 8vw;
    border: 0;
    background: linear-gradient(to left, #6FBA91 100%, #76DFA6 100%);
    font-weight: 800;
}

.B12_F7 {
    display: block;
    width: 61vw;
    height: 23vw;
    text-align: left;
    margin-left: -7vw;
    margin-top: 9.903381642512077VW;
}

.EB12_F7 {
    display: none;
}

.B12_F7 p {
    font-size: 2.898550724637681VW;
}

.B12_F7 span {
    color: #0A4CA1;
    font-weight: 800;
}

.Bloque13 {
    display: block;
    width: 100%;
    height: 82.85024154589372VW;
    background-image: url("../../assets/Rectangle_25P.png");
}

.Bloque14 iframe {
    width: 100%;
    height: 74.63768115942028VW;
}

@media (min-width: 768px) {

    .Bloque1 {
        background: linear-gradient(243.78deg, #6FBA91 17.49%, #0A4CA1 99.32%);
        display: block;
        height: 33.90625VW;
        width: 100%;
        padding-top: 0vw;
    }

    .B1F1 {
        display: flex;
        place-content: center;
        margin: 0vw 6vw;
        padding-bottom: 1vw;
        border-bottom: 1px solid white;
    }

    .B1F1C1 {

        margin-right: 33vw;
    }

    .B1F1C1_C1 {
        margin-top: 0.1VW;
        margin-right: 1.6145833333333335VW;
    }

    .B1F1C1_C1 img {
        width: 4.53125VW;
        height: auto;
    }

    .B1F1C1_C2 {
        width: 8vw;
        height: 1.5vw;
        padding-top: 0.5vw;
        text-transform: uppercase;
        margin-left: 0vw;
    }

    .B1F1C1_C2 p {
        font-size: 0.78125VW;
        line-height: 0.9208333333333334VW;
        text-align: left;
    }

    .B1F1C2 {
        display: flex;
        align-items: center;
    }

    .B1F1C2_C1 ul {
        display: flex;
        color: white;
        list-style: none;
        font-weight: 500;
        font-size: 0.6770833333333334VW;
        margin-bottom: 0vw;
    }

    .B1F1C2_C1 li {
        cursor: pointer;
        align-self: center;
        position: relative;
    }

    .B1F1C2_C1>ul>li {
        cursor: pointer;
        align-self: center;
    }

    .B1F1C2_C1>ul>li:first-of-type {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .B1F1C2_C1>ul>li:first-of-type:hover {
        text-decoration: underline;
    }

    .B1F1C2_C1>ul>li {
        width: 5.989583333333334vw;
        height: 1.8229166666666667vw;
    }

    .B1F1C2_C1 a {
        color: white;
        white-space: nowrap;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
    }

    .B1F2 {
        display: flex;
    }

    .B1F2C1 {
        width: 43vw;
        height: 10vw;
        margin-top: 8.739583VW;

    }

    .B1F2C1_F1 p {
        color: white;
        font-weight: 800;
        font-size: 3.90625VW;
        line-height: 4.6875VW;
        margin-bottom: 2VW;
    }

    .B1F2C1_F2 {
        width: 20vw;
        text-align: left;
        margin-left: 5vw;
    }

    .B1F2C1_F2 p {
        width: 20vw;
        color: white;
        font-weight: 400;
        font-size: 0.78125VW;
        line-height: 1.1213541666666667VW;
    }

    .B1F2C2 {
        width: 35.041667VW !important;
        height: auto !important;
        margin-left: 10vw;
        margin-bottom: 5vw;
        margin-top: -2.68vw !important;
    }






    .Bloque2 {
        display: flex;
        height: 37.135416666666664VW;
        width: 100%
    }

    .B2_F1 {
        margin-left: 8.802083333333334VW;
        margin-top: 11.145833333333334VW;
    }

    .B2B1 {
        display: block;
    }

    .B2B1 img {
        width: 50VW;
        height: 37.135416666666664VW;
    }

    .B2_C1 img {
        width: 3.958333333333333VW;
        height: 4.84375VW;
        margin-right: 2.2916666666666665VW;
    }

    .B2_C2 {
        width: 21vw;
        padding-top: 2vw;
    }

    .B2_C2 p {
        font-size: 1.5625VW;
        line-height: 1.875VW;
    }

    .B2_F2 {
        width: 16vw;
        height: 8vw;
        margin-top: 2.2916666666666665VW;
        margin-left: 10vw;
    }

    .B2_F2 p {
        width: 18.5vw;
        font-size: 0.8333333333333334VW;
        line-height: 1.4833333333333334VW;
        text-align: left;
    }


    .Bloque3 {
        display: none;
    }

    .Bloque4 {
        display: flex;
        width: 100%;
        height: 37.135416666666664VW;
        text-align: -webkit-center;
    }

    .B4_F1 {
        margin-top: 13.229166666666666VW;
        width: 50VW;
    }

    .B4_C1 img {
        width: 3.4375000000000004VW;
        height: 4.427083333333334VW;
        margin-right: 2.25VW;
    }

    .B4_C2 {
        width: 16vw;
        text-align: left;
    }

    .B4_C2 p {
        font-size: 1.5625VW;
        color: #548EBA;
        font-weight: 800;
    }

    .B4_F2 {
        width: 16vw;
        height: 3vw;
        margin-top: 0.2395833333333335VW;
        margin-left: 6vw;
    }

    .B4_F2 p {
        font-size: 0.8333333333333334VW;
        line-height: 1.4833333333333334VW;
        text-align: left;
    }

    .B4B2 {
        display: block;
    }

    .B4B2 img {
        width: 48.7VW;
        height: 37.135416666666664VW;
    }

    .Bloque5 {
        display: none;
    }

    .Bloque6P {
        display: none;
    }

    .Bloque6 {
        display: flex;
        width: 100%;
        height: 37.135416666666664VW;
    }

    .B6B1 {
        display: block;
    }

    .B6B1 img {
        width: 50VW;
        height: 37.135416666666664VW;
    }

    .B6_F1 {
        width: 48.7VW;
        margin-top: 12.291666666666666VW;
    }

    .B6_C1 img {
        width: 3.802083333333333VW;
        height: 4.010416666666667VW;
        margin-right: 2.34375VW;
    }

    .B6_C2 {
        width: 16vw;
    }

    .B6_C2 p {
        display: block;
        font-size: 1.5625VW;
        color: #548EBA;
        font-weight: 800;
        background-color: white;
        border: 0;
    }

    .B6_C2 pre {
        display: none;
    }

    .B6_F2 {
        width: 18vw;
        height: 8vw;
        margin-top: 1.6666666666666667VW;
    }

    .B6_F2 p {
        display: block;
        background-color: white;
        border: 0;
        font-size: 0.8333333333333334VW;
        font-weight: 400;
        line-height: 1.4833333333333334VW;
        letter-spacing: 1.5%;
        text-align: left;
        color: #A6A6A6;
    }

    .B6_F2 pre {
        display: none;
    }


    .Bloque7 {
        display: none;
    }

    .Bloque8P {
        display: none;
    }

    .Bloque8 {
        width: 100%;
        height: 37.135416666666664VW;
        text-align: -webkit-center;
        display: flex;
    }

    .B8_F1 {
        display: flex;
        justify-content: center;
        margin-top: 10.885416666666666VW;
        width: 50vw
    }

    .B8_C1 img {
        width: 3.28125VW;
        height: 3.854166666666667VW;
        margin-right: 3.5VW;
    }

    .B8_C2 {
        width: 20vw;
        padding-top: 1vw;
    }

    .B8_C2 p {
        font-size: 1.5625VW;
        margin: 0;
    }

    .B8_F2 {
        width: 18vw;
        height: 14vw;
        margin-top: 3.125VW;
        margin-left: 4.7vw;
    }

    .B8_F2 p {
        font-size: 0.8333333333333334VW;
        line-height: 1.4833333333333334VW;
        letter-spacing: 1.5%;
        text-align: left;
        color: #A6A6A6;
        margin-bottom: 2vw;
    }

    .B8_F2 ul {
        width: 26vw;
        font-size: 0.8333333333333334VW;
        line-height: 1.4833333333333334VW;
        padding-left: 1.5vw;
        columns: 2;
    }

    .B8B2 {
        display: block;
    }

    .B8B2 img {
        width: 48.7vw;
        height: 37.135416666666664VW;
    }



    .Bloque9 {
        display: none;
    }

    .Bloque10 {
        width: 100%;
        height: 37.135416666666664VW;
        text-align: -webkit-center;
        display: none;
    }

    .B10B1 {
        display: block;
    }

    .B10B1 img {
        width: 50vw;
        height: 37.135416666666664VW;
    }

    .B10_F1 {
        width: 48.7VW;
        height: 2VW;
        padding-left: 15vw;
        margin-top: 15.416666666666668VW;
    }

    .B10_F1 p {
        font-size: 1.5625VW;
    }

    .B10_F2 {
        display: flex;
        justify-content: center;
        margin-top: 2.083333333333333VW;
    }

    .B10_F2 ul {
        font-size: 0.8333333333333334VW;
        line-height: 1.2750000000000001VW;
        margin-right: 2vw;
    }


    .Bloque11 {
        display: none;
    }


    .Bloque12 {
        width: 100%;
        height: 53.697916666666664VW;
        padding-top: 0VW;
        display: flex;
    }

    .B12B1 {
        display: block;
    }

    .B12B1 img {
        width: 46.875VW;
        height: 53.697916666666664VW;
    }

    .B12_Esp {
        padding-left: 8.229166666666666VW;
        padding-top: 4.09375VW;
    }

    .B12_F1 {
        width: 28vw;
        height: 10vw;
        margin-bottom: 2VW;
        margin-right: -8vw;
        margin-left: -19vw;
    }

    .B12_F1 p {
        font-size: 2.604166666666667VW;
        line-height: 3.125VW;
    }

    .B12_F2 {
        width: 13vw;
        height: 6vw;
        margin-bottom: 0vw;
        display: inline-grid;
    }

    .B12_F2 label {
        font-size: 0.8333333333333334VW;
        line-height: 1VW;
        margin-bottom: .9vw;
        padding-left: 0vw;
        align-self: center;
    }

    .B12_F2 input {
        width: 11.458333333333332VW;
        height: 3.177083333333333VW;
        border-radius: 8vw;
        padding: 0vw 1.3vw;
    }

    .B12_F5 {
        width: 37vw;
        height: 17vw;
        text-align: left;
        margin-bottom: 2VW;
        padding-top: 2.864583333333333VW;
        margin-left: -2vw;
    }

    .B12_F5 label {
        font-size: 0.8333333333333334VW;
        line-height: 1VW;
        margin-bottom: .9vw;
        padding-left: 0vw;
    }

    .B12_F5 textarea {
        width: 36.5625VW;
        height: 7.03125VW;
        border-radius: 1.5625VW;
        padding: 0.5vw 1.3vw;
    }

    .B12_F6 button {
        width: 36.822916666666664VW;
        height: 4.739583333333333VW;
        border-radius: 8vw;
        margin-left: -2vw;
    }

    .B12_F7 {
        display: none;
    }

    .EB12_F7 {
        display: block;
        width: 32vw;
        height: 5vw;
        text-align: left;
        margin-top: 1.903382VW;
        margin-left: -7vw;
    }

    .Eb12_F1 {
        display: flex;
    }

    .EB12_F7 p {
        font-size: 0.7291666666666666VW;
        margin-right: 1vw;
    }

    .EB12_F7 span {
        color: #0A4CA1;
        font-weight: 800;
    }


    .Bloque13 {
        display: none;
    }

    .Bloque14 iframe {
        width: 100%;
        height: 21.145833333333332VW;
    }

    .wrapper {
        box-sizing: border-box;
        display: grid;
        grid-template-rows: 0fr;
        transition: grid-template-rows 0.3s linear;
        position: absolute;
        background-image: linear-gradient(0deg, #183B68 81.32%, rgba(48, 117, 206, 0.00) 100%);

        width: 20.885416666666668vw;
        border-radius: 0 0 1.0416666666666665vw 1.0416666666666665vw;
        top: 100%;
        left: -11.854166666666668vw;
    }

    .wrapper.extended {
        grid-template-rows: 1fr;
    }

    .wrapper * {
        box-sizing: border-box;
    }

    .wrapper>div {
        overflow: hidden;
        padding: 0 2.1875vw;
    }

    .wrapper h2 {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        font-size: 1.25vw;
        line-height: 100%;
        margin: 5vw 0 0.8333333333333334vw;
    }

    .wrapper h2+a {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(0deg, #4F8B77 0%, #6FBA91 100%);
        color: #FFF;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 500;

        font-size: 0.9895833333333333vw;
        line-height: normal;
        width: 100%;
        height: 2.7604166666666665vw !important;
        border-radius: 2.604166666666667vw;
        margin: 0 0 1.1979166666666667vw;


        width: 100%;
        height: 2.7604166666666665vw !important;
        border-radius: 2.604166666666667vw;
        margin: 0 0 1.1979166666666667vw;
    }

    .wrapper ol {
        padding: 0 0 0 1.0416666666666665vw;
    }

    .wrapper li {
        margin: 0;
        text-align: start;
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 400;

        font-size: 0.8333333333333334vw;
        line-height: 150%;
    }

    .wrapper li a {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        display: inline !important;
        font-size: 0.8333333333333334vw;
        line-height: 1.25vw;
    }

    .wrapper h3 {
        color: #BBEFE3;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;
        text-align: start;

        font-size: 0.8333333333333334vw;
        line-height: 1.25vw;
        margin: 1.6666666666666667vw 0 1.5625vw;
    }

    .wrapper button {
        border: 0;
        padding: 0;
        background-color: transparent;
        color: #6FBA91;
        font-family: Arial, Helvetica, sans-serif;
        font-style: normal;
        font-weight: 700;

        font-size: 0.7291666666666666vw;
        line-height: 171.429%;
        text-decoration-line: underline;
        margin: 0 0 0.9895833333333333vw;
    }

}
</style>